.privacy {
  width: 90%;
  margin: 5% auto;
  box-sizing: border-box;
  /* border: 1px solid black; */
  /* text-align: center; */
}
.privacy h1 {
  font-size: 36px;
  font-weight: normal;
  text-align: center;
}
.privacy h2 {
  font-weight: normal;
}
.privacy h3 {
  font-weight: normal;
}
