/* Styles of Client Order Data */

.container1 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;
}
.orderContainer {
  margin: 0 10px;
  padding: 10px;
  border: 1px solid #ff4141;
  border-radius: 12px;
}
.idStatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
  /* border: 1px solid black; */
  margin-top: -10px;
}
.riderStatus {
  display: flex;
  align-items: center;
  /* border: 1px solid black; */
}
.pickStatus {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  display: inline-block;
  margin: 15px;

  /* border: 1px solid black; */
}
.blue {
  background-color: rgb(39, 208, 255);
}

.green {
  background-color: #9dff00;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.accessContainer {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 7px;
  align-items: center;
}

.priceContainer {
  /* color: #ffa1a1; */
  color: #ff4141;
  font-weight: bold;
  font-size: 18px;
}

/*  */

.container {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-evenly;
  margin-top: 25px;
}
.print button {
  border: none;
  background-color: #ff4141;
  padding: 8px;
  border-radius: 10px;
  margin: 20px 0px 20px 85%;
  color: white;
  font-weight: bold;
  width: 110px;
}
.profile {
  /* border: 1px solid black; */
  text-align: center;
  align-items: center;
  padding: 10px;
  margin-top: 60px;
}
.profileInfo {
  border: 1px solid black;
  border-radius: 12px;
}
.profile p {
  align-items: starts;
  /* border: 1px solid black; */
  padding: 5px;
  font-size: 18px;
}
.profile button {
  background-color: #ff4141;
  border: none;
  padding: 8px;
  border-radius: 10px;
  color: white;
  margin-top: 25px;
}

.statusTypes {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-evenly;
}
.statusTypes h3 {
  /* border: 1px solid black; */
  padding: 5px;
  cursor: pointer;
}

/* .table {
  margin-top: 100px;
  width: 800px;
  height: 500px;
  overflow: auto;
}
.styledTable {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styledTable thead tr {
  background-color: #ff4141;
  color: #ffffff;
  text-align: left;
  position: sticky;
  top: 0;
}
.headTr th {
  text-align: center;
}
.styledTable th,
.styledTable td {
  padding: 12px 10px;
  text-align: center;
  font-size: 1.1rem;
}
.styledTable td:nth-child(2) {
  padding: 13px;
  font-size: 1.2rem;
}

.styledTable tbody tr {
  border-bottom: 1px solid #dddddd;
}
.styledTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styledTable tbody tr:last-of-type {
  border-bottom: 2px solid #ff4141;
}

.orderData {
  text-align: right;
} */

/* End */

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
