/* /////// */
/* /////// */
/* CSS of Hero Section */
/* /////// */
/* /////// */
/* /////// */

/* .hero {
  background: #d5d4d4;
} */

/* .hero,
.heroCont,
.heroCont1,
.heroCont2,
.distance {
  border: 1px solid black;
} */

.heroCont {
  width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 60px;
  /* border: 1px solid black; */
  align-items: center;
}

.heading h1 {
  font-size: 40px;
  font-weight: 700;
}
.distance {
  /* background-color: white; */
  /* background: #f3eeee; */
  background-color: #f4f4f4;
  padding-bottom: 20px;
  margin: 40px auto;
  width: 60%;
  border-radius: 12px;
  border: 1px solid white;
  text-align: center;
  align-items: center;
}

.distance input,
.distance button {
  /* border: 1px solid black; */
  border: 1px solid #d5d4d4;

  border-radius: 7px;
  padding: 8px;
  display: block;
  margin: 15px auto 0px auto;
  width: 80%;
}
.distance .destination {
  margin-top: 0px;
}
.distance button {
  background-color: #ff4141;
  color: white;
  border-radius: 15px;
  width: 85%;
  padding: 10px;
}
.distance .clear {
  place-content: right;
  margin-left: 90%;
  margin-top: 2px;
  margin-bottom: 0;
  padding: 2px;
  width: fit-content;
  color: black;
  cursor: pointer;
}

.price {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
  align-items: center;
  color: black;
  font-weight: bold;
}

.gotoLogin,
.riderContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}
.gotoLogin button,
.riderContainer button {
  width: 120px;
  padding-bottom: 10px;
}
.riderContainer button {
  background-color: white;
  color: black;
}

.heroCont2 img {
  /* border: 1px solid black; */
  width: 100%;
  height: 80%;
  /* text-align: center; */
  /* align-items: center; */
  background-size: cover;
}

/* /////// */
/* /////// */
/* CSS of Delivery Types Section */
/* /////// */
/* /////// */
/* /////// */

/* .allTypes,
.types,
.imgContainer {
  border: 1px solid black;
} */

.deliveryTypes {
  /* width: 100vw; */
  background-color: white;
}

.allTypes {
  width: 80%;
  margin: 0 auto;
  /* border: 1px solid black; */
}
.allTypes h1 {
  font-weight: 700;
  font-size: 48px;
  padding: 45px 0;
}

.types {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* border: 1px solid black; */
}
.imgContainer {
  /* border: 1px solid black; */
  text-align: center;
  align-items: center;
  margin-top: 25px;
}
.imgContainer img {
  width: 100%;
  background-size: cover;
  height: 100%;
}
.imgContainer img:hover {
  cursor: pointer;
  scale: 1.1;
  transition: all 0.5s ease-in-out;
}

/* /////// */
/* /////// */
/* CSS of WhatsApp Delivery Section */
/* /////// */
/* /////// */
/* /////// */

.whatsapp {
  width: 80%;
  margin: 5% auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  color: white;
  border-radius: 15px;
  background-color: #2c9c65;
  gap: 20px;
}
/* .whatsapp,
.deliver,
.deliverConnect {
  border: 1px solid black;
} */

.deliver h2 {
  font-size: 30px;
  font-weight: 700;
}
.deliver h2,
.deliver p {
  width: 80%;
  margin: 25px auto;
}
.deliverConnect {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.deliverConnect button {
  width: 150px;
  border-radius: 12px;
  padding: 10px;
  margin: 0 auto;
  font-weight: bold;
}
.deliverConnect button:nth-child(1) {
  background-color: white;
  color: #2c9c65;
  border: none;
}
.deliverConnect button:nth-child(2) {
  background-color: #2c9c65;
  color: white;
  border: 1px solid white;
}

/* /////// */
/* /////// */
/* CSS of Payment Section */
/* /////// */
/* /////// */
/* /////// */

.payment {
  background-color: #d9d9d9;
  width: 80%;
  margin: 2% auto;
  padding-bottom: 30px;
  border-radius: 15px;
}
.payment h2 {
  text-align: center;
  font-weight: 700;
  font-size: 40px;
  padding-top: 15px;
}
.paymentImg {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 15px;
}
.paymentImg img {
  height: 118px;
  width: 72px;
}

/* /////// */
/* /////// */
/* CSS of Go Green Section */
/* /////// */
/* /////// */
/* /////// */

/* .goGreen {
  text-align: left;
  align-items: center;
  background-color: #b3e823;
  padding: 10px;
  width: 80%;
  margin: 2% auto;
  border-radius: 15px;
} */
.greenContainer h2 {
  font-size: 30px;
}
.greenContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  align-items: center;

  background-color: #b3e823;
  padding: 10px;
  width: 80%;
  margin: 2% auto;
  border-radius: 15px;
}
.greenContainer .heading {
  align-items: center;
  text-align: left;
}
.greenContainer .heading h4 {
  font-size: 16px;
  font-weight: normal;
}

.greenImage img {
  width: 100%;
  height: 100%;
  text-align: center;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .distance {
    width: 80%;
  }
  .deliverConnect {
    padding-bottom: 20px;
  }
  .deliverConnect button {
    width: 120px;
  }
}

@media screen and (max-width: 600px) {
  .heroCont1 {
    text-align: center;
  }
  .distance {
    width: 100%;
  }
  .whatsapp {
    width: 90%;
  }
  .greenContainer {
    width: 90%;
  }
}
