/* Styles of Client Order Data */

input[type="number"]::-webkit-inner-spin-button {
  display: none;
}
button {
  cursor: pointer;
}

.container {
  display: flex;
  justify-content: space-around;
  /* width: 90%; */
  margin-top: 25px;
}
.table {
  margin: 25px auto;
  width: 870px;
  overflow: auto;
  /* border: 1px solid black; */
}
.print button {
  border: none;
  background-color: #ff4141;
  padding: 10px;
  border-radius: 8px;
  margin: 60px 0px 20px 70%;
  color: white;
  font-weight: bold;
  width: 150px;
}
.profile {
  /* border: 1px solid black; */
  text-align: center;
  align-items: center;
  padding: 10px;
  margin-top: 20px;
}
.profileInfo {
  border: 1px solid black;
  border-radius: 12px;
}
.profile p {
  align-items: starts;
  /* border: 1px solid black; */
  padding: 5px;
  font-size: 18px;
}
.profile button {
  background-color: #ff4141;
  border: none;
  padding: 8px;
  border-radius: 10px;
  color: white;
}
.styledTable {
  border-collapse: collapse;
  margin: auto;
  font-size: 0.9em;
  font-family: sans-serif;

  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styledTable thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  position: sticky;
  top: 0;
}
.headTr th {
  text-align: center;
}
.styledTable th,
.styledTable td {
  padding: 12px 10px;
  text-align: center;
}

.styledTable tbody tr {
  border-bottom: 1px solid #dddddd;
}
.styledTable tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styledTable tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.orderData {
  /* border: 1px solid black; */
  text-align: right;
}
.orderData button {
  margin-right: 70px;
  border: none;
  color: white;
  padding: 8px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  /* background-color: #008cba; */
  background-color: #009879;
}

/* End */

/* .order form {
  border: 1px solid grey;
} */

.notification {
  /* border: 1px solid black; */
  width: 60%;
  margin: 30px auto;
  text-align: center;
}

.notification marquee {
  /* border: 1px solid black; */
  width: 50%;
  font-size: 1.5rem;
  color: #ff4141;
  border-radius: 12px;
}

.weight,
.pickUp,
.drop {
  width: 50%;
  margin: 3% auto;
  border: 1px solid grey;
}
.weight {
  border: none;
}
/* .weight h3 {
  margin-left: 35px;
} */

.weightClass {
  display: flex;
  align-items: center;
  text-align: center;
}
.weightClass p {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  border: 1px solid silver;
  margin: 0 auto;
  cursor: pointer;
}
/* .weightClass p:hover {
  border: 1px solid red;
} */
.selected {
  border: 1px solid purple;
}

.riderContainer {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.riderContainer button {
  border: none;
  padding: 12px;
  background-color: white;
  border-radius: 12px;
}

.addresses label,
.secureParcel label {
  margin: 16px 0 16px 15px;
  font-weight: bold;
}
.pickUp,
.drop {
  background-color: white;
  border-radius: 8px;
  /* background-color: #ff4141; */
  /* color: white; */
  /* opacity: 0.2; */
}
.pickUp input {
  border: 1px solid red;
}
.pick {
  display: block;
  margin-left: 15px;
}
.courierDetails input {
  width: 80%;
  border: 1px solid silver;
  border-radius: 4px;
  padding: 12px;
  margin-left: 15px;
}
.numberContainer {
  border: 1px solid silver;
  width: fit-content;
  margin-left: 15px;
}
.numberContainer span {
  margin-left: 15px;
  margin-left: 0;
}
.courierDetails .number {
  border: none;
  border-radius: none;
  /* border-bottom: 1px solid black; */
  width: 60%;
  margin-left: 0;
}

.remarks {
  margin: 20px auto;
}
.dropLocationContainer {
  width: 100%;
  /* border: 1px solid black; */
  display: grid;
  place-items: end;
  /* align-items: end; */
}
.dropLocation {
  /* position: relative; */
  border: 1px solid silver;
  padding: 3px;
  border-radius: 10px;
  width: fit-content;
  margin: 20px;
  /* left: 83%; */
  cursor: pointer;
}
.removeDropLocationContainer {
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.removeDropLocation {
  /* border: 1px solid black; */
  /* position: relative; */
  border-radius: 10px;
  width: fit-content;
  margin: 25px;
  padding: 5px;
  border: 1px solid silver;
  /* left: 65%; */
  cursor: pointer;
  font-weight: normal;
}
/* .clientDatePicker {
  width: 50%;
  margin: 40px auto;
  background-color: white;
  padding: 15px 5px;
  border: 1px solid grey;
  display: flex;
  justify-content: space-between;
} */
.packageType {
  width: 50%;
  margin: auto;
  background-color: white;
  padding: 15px 0;
  border: 1px solid grey;
}
.packageType input {
  margin-top: 10px;
  display: block;
}
.packageType p {
  display: inline-block;
  margin: 10px 10px;
  padding: 5px;
  border: 1px solid grey;
  border-radius: 8px;
  cursor: pointer;
}

.secureParcel {
  width: 50%;
  margin: 20px auto;
  background-color: white;
  padding: 15px 0;
  border: 1px solid grey;
}

.secureParcel span {
  margin-left: 20px;
  /* color: rgba(150, 148, 147, 1); */
}

.secureParcel input {
  margin-top: 15px;
}
.secureParcel p {
  width: 70%;
  margin-left: 20px;
}

.bag {
  width: 50%;
  margin: 10px auto;
}
.bag input {
  width: fit-content;
}

/* .payment,
.paymentMode {
  border: 1px solid black;
} */

.payment {
  width: 50%;
  margin: 20px auto;
  background-color: white;
  padding: 15px 0;
  border: 1px solid grey;
}
.payment label {
  font-size: 20px;
  font-weight: normal;
}
.cashPickup {
  margin-top: 20px;
}
.cashPickup button {
  /* padding: 15px; */
  width: 80px;
  height: 40px;
  border: none;
  margin: 12px;
  border-radius: 12px;
}

.paymentbank {
  margin: 15px 20px;
}
.paymentbank img {
  width: 50%;
  height: 100%;
  background-size: contain;
}
.coupons {
  width: 50%;
  margin: 0 auto;
}
.coupons input {
  width: 55%;
  margin-right: 10px;
}
.coupons button {
  padding: 10px;
  border: none;
  display: inline;
}

.total {
  width: 50%;
  margin: 20px auto;
  background-color: white;
  padding: 15px 0;
  /* border: 1px solid grey; */
  text-align: center;
}
.total h2 {
  font-weight: normal;
  margin-left: 20px;
}
.total button {
  background-color: #ff4141;
  width: 200px;
  color: white;
  border-radius: 12px;
  border: none;
  padding: 15px;
  margin-left: 20px;
}
.clause {
  /* background-color: #ff4141; */
  border-bottom: 2px solid #ff4141;
}
.total p {
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .notification {
    width: 65%;
  }
  .container {
    display: flex;
    flex-direction: column;
  }
  .weight,
  .pickUp,
  .drop,
  .packageType,
  .secureParcel,
  .bag,
  .payment,
  .total {
    width: 80%;
  }
  .total button {
    margin: 20px;
  }
  .table {
    width: 550px;
    height: 400px;
  }
  .print button {
    margin-left: 60%;
  }
  .coupons {
    width: 80%;
    text-align: center;
  }
  .coupons input {
    margin: 15px;
  }
}
@media screen and (max-width: 568px) {
  .table {
    width: 500px;
    height: 400px;
  }
  .notification,
  .notification marquee {
    width: 80%;
  }
}
@media screen and (max-width: 468px) {
  .table {
    width: 400px;
    height: 400px;
    overflow: auto;
  }
}
@media screen and (max-width: 438px) {
  .table {
    width: 320px;
    height: 400px;
    overflow: auto;
  }
  .print button {
    margin-left: 50%;
  }
}

.placeOrder {
  margin: 10% auto;
  width: fit-content;
}
.placeOrder button {
  text-align: center;
  padding: 20px;
  border: none;
  border: 1px solid silver;
  background-color: #ff4141;
  color: white;
  border-radius: 12px;
}
.notLoggedin {
  width: 50%;
  margin: 20% auto;
  /* place-content: center; */
  /* border: 1px solid black; */
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}
.orderStatus {
  /* border: 1px solid black; */
  width: 50%;
  margin: 0 auto;
}
.addresses {
  margin-bottom: 50px;
}
.required {
  color: red;
  font-weight: bold;
}
