.nav {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}
.logo,
.items {
  margin: 0;
}
.logo img {
  width: 99%;
  height: 70px;
  background-size: contain;
  /* background-size: cover; */
}
.logo2 {
  display: none;
}

.items ul {
  display: flex;
  justify-content: space-evenly;
}
.items ul li {
  list-style: none;
  margin: 0 15px;
  padding: 5px 8px;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
}
.items ul li a {
  text-decoration: none;
  color: white;
  color: black;
}
.items ul li a:active,
.items a.active,
.items a:hover {
  padding-bottom: 0.25rem;
  border-bottom: 2px solid #fe0000;
}
.logout {
  background-color: #fe0000;
  border: none;
  color: white;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 5px;
}
@media screen and (max-width: 768px) {
  .logo1 {
    display: none;
  }
  .logo2 {
    display: block;
    height: 75px;
    width: 73px;
  }
  .items ul {
    padding-left: 0px;
  }
  .items ul li {
    margin: 0 5px;
    padding: 3px 5px;
    font-size: 12px;
    /* border: 1px solid black; */
    width: 65px;
  }
  .logout {
    margin: 0 5px;
    padding: 1px 3px;
    font-size: 12px;
  }
}
