/* .footer,
.footerElements,
.logo,
.logoElements,
.socials,
.items1,
.items2,
.copyright {
  border: 1px solid black;
} */
.footer {
  background: #2d2928;
  /* position: absolute;
  bottom: 0;
  width: 100%; */
}

.footerElements {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 80px auto;
}
.logo {
  margin: auto 0;
}
.logo a {
  text-decoration: none;
}
.logoElements {
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: -1px;
  color: white;
  text-align: center;
  cursor: pointer;
}

.socials {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin-top: 30px;
}
.socials img {
  cursor: pointer;
  height: 32px;
  width: 32px;
}
.items1 a,
.items2 a {
  text-decoration: none;
}

.items1 p,
.items2 p {
  color: rgba(150, 148, 147, 1);
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.copyright {
  width: 80%;
  margin: 0 auto;
  border-top: 1px solid rgba(150, 148, 147, 1);
  text-align: center;
  color: rgba(150, 148, 147, 1);
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 20px;
}
