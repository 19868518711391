.faqSection {
  /* border: 1px solid black; */
  width: 80%;
  margin: 40px auto;
  align-items: center;
  background-color: #d9d9d9;
  padding: 10px;
  border-radius: 15px;
}
.faqSection h2 {
  text-align: center;
  font-size: 32px;
}
.faq {
  width: 90%;
  /* border: 1px solid black; */
  margin: 15px auto;
  cursor: pointer;
  border-radius: 10px;
  background-color: #f4f4f4;
  padding-bottom: 10px;
}
.faqHead {
  /* border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.openClose {
  margin-right: 30px;
  /* border: 1px solid black; */
}
.faq h3 {
  padding: 10px;
  margin-left: 10px;
}
.faq p {
  /* padding: 20px; */
  margin: 20px;
}
